import React from 'react';
import { useNavigate } from 'react-router-dom';
import MessageIcon from '@mui/icons-material/Message';
import EmailIcon from '@mui/icons-material/Email';
import { HashLink as Link } from 'react-router-hash-link';

import '../../index.css';
import ButtonBase from '../../components/ButtonBase';
import ServiceBlock from './ServiceBlock';
import PhoneRepair2 from '../../assets/images/phone-repair-2.jpg';
import ServiceClean from '../../assets/images/service-clean.jpg';
import ServiceTechnical from '../../assets/images/service-technical.jpg';
import ContactUS from './ContactUS';
import BackgroundImg from '../../assets/images/phone-repair.jpg';
import LogoImg from '../../assets/images/phone-revival-logo.png';
import messageUs from '../../utils/messageUs';
// import BackgroundImage from '../assets/images/phone-repair.jpg';

const Home = () => {
  const navigate = useNavigate();
  return (
    <div>
      {/* Landing Section */}
      {/* <div style={{ backgroundImage: `url(${BackgroundImage})`}}> */}
      <div
        className="landing-img"
        style={{
          background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${BackgroundImg})`,
        }}
      >
        {/* text alignment */}
        <div className="mx-auto w-10/12 md:w-3/5 py-[20%]">
          <div className="rounded-[50%] w-[80%] h-[80%] m-auto p-5 bg-[#ffffff8f]">
            <img alt="" src={LogoImg} />
          </div>
        </div>
      </div>

      <div className="layout-container">
        <div className="flex flex-col gap-4 mt-24">
          <div className="text-6xl font-['Allura'] text-pr-purple">About</div>
          <div className="sm:text-xl md:text-2xl">
            Do you have something that’s not working like it should? you’ve come
            to the right place. Phone Revival is a startup cell phone repair
            located in Monsey, New York. We treat your phone with the care it
            deserves. Our professionals take your broken/unusable phone and
            revive it. We have qualified experts and experienced repair
            professionals to get your phone back into shape, backed by our parts
            and labor guarantee.
          </div>
        </div>
        {/* Quick Access Buttons */}
        <div className="mt-24 mb-24 flex flex-col gap-14 items-center">
          <div className="md:text-2xl">
            SCHEDULE YOUR REPAIR. START A SERVICE FORM
          </div>
          <ButtonBase
            action={() => navigate('/services')}
            twStylesContainer="button-bg-transparent"
            twStyles="font-semibold"
          >
            Service Form
          </ButtonBase>
        </div>
        <div className="flex mx-auto w-fit gap-4 flex-col sm:flex-row sm:gap-14">
          <ButtonBase
            noBorder
            action={() => {
              messageUs();
            }}
            twStyles="flex items-center gap-4"
          >
            <MessageIcon />
            Message US
          </ButtonBase>
          <Link smooth to="/#contact-us">
            <ButtonBase
              noBorder
              action={() => {}}
              twStyles="flex items-center gap-4"
            >
              <EmailIcon />
              Email US
            </ButtonBase>
          </Link>
        </div>
      </div>

      {/* Service List Section */}
      <div className="mx-auto w-fit text-8xl my-32 text-pr-purple font-['Allura']">
        Services
      </div>
      <div className="flex flex-col gap-12">
        {[
          {
            title: 'Repair',
            id: 'repair',
            body: `
            We specialize in Repair services, offering only top quality and efficient results. 
            When you book this service, like all of our others, 
            we guarantee you will be satisfied with the process and result.`,
            img: PhoneRepair2,
          },
          {
            title: 'Cleaning',
            id: 'cleaning',
            body: `
              Our Cleaning services are designed to make your life easier, 
              with convenient and comprehensive support when you need it most. 
              Get in touch today to learn more about this service and how it suits your needs.
            `,
            img: ServiceClean,
          },
          {
            title: 'Technical Issues',
            id: 'technicalIssues',
            body: `
            This is one of our most popular services, 
            offered for a variety of models and brands. 
            Feel free to reach out with any questions for our team, 
            and make sure to book this service ahead of time.
            `,
            img: ServiceTechnical,
          },
        ].map((item) => (
          <ServiceBlock item={item} />
        ))}
        {/* Contact US */}
        <ContactUS />
      </div>
    </div>
  );
};

export default Home;
