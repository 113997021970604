import { useState } from "react";
import { createContainer } from "unstated-next";

const useServiceSelect = () => {
  const [defaultService, setDefaultService] = useState('');

  return {
    defaultService,
    setDefaultService
  };
};

export default createContainer(useServiceSelect);
