import React from 'react'
import ServiceForm from './ServiceForm'

const Services = () => {
  return (
    <div className="mx-auto w-4/5 md:w-1/2 xl:w-2/5">
      <div className="text-center mt-10 text-7xl font-['Allura'] text-pr-purple">Service Form</div>
      <ServiceForm />
    </div>
  )
}

export default Services