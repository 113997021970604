import React from 'react';

const ButtonBase = ({ children, action, noBorder, twStyles, twStylesContainer }) => {
  const classList = `button ${noBorder ? 'button-plain' : 'button-border'} ${' ' + twStylesContainer || ''}`
  return (
    <div className={classList} onClick={action || null}>
      <div className={twStyles || null}>{children || 'Button Base'}</div>
    </div>
  );
};

export default ButtonBase;
