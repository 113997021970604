const options = (list) => {
  if (list.length < 2 || list.length > 3) return null;
  return {
    'Screen': list[0],
    ...(list[1] !== 0 && {'Back Glass': list[1]}),
    ...(list.length === 3 && {'Battery': list[2]})
  };
};

const phoneRepairPrice = {  // Screen, Back Glass, Battery
  'iPhone 13 pro max':    options([445, 250]),
  'iPhone 13 pro':        options([435, 250]),
  'iPhone 13':            options([315, 250]),
  'iPhone 13 mini':       options([405, 250]),

  'iPhone 12 pro max':    options([375, 200, 115]),
  'iPhone 12 / 12 pro':   options([375, 200, 115]),
  'iPhone 12 mini':       options([235, 200, 115]),

  'iPhone 11 pro max':    options([215, 145, 115]),
  'iPhone 11 pro':        options([135, 145, 115]),
  'iPhone 11':            options([125, 145, 85]),

  'iPhone xs / xs max':   options([155, 100, 80]),
  'iPhone xr':            options([115, 100, 65]),
  'iPhone x':             options([115, 100, 65]),

  'iPhone 8 / 8 Plus, SE2':   options([85, 100, 50]),
  'iPhone 7 / 7 Plus':        options([75, 0, 50]),
  'iPhone 6 / 6S Plus, SE1':  options([65, 0, 50])
};

export default phoneRepairPrice;