import React from 'react'

const Footer = () => {
  return (
    <div className="bg-gray-200 py-3 px-10 md:px-40 text-sm mt-12">
      Copyright ©2022 PhoneRevival.com phone repair service
    </div>
  )
}

export default Footer