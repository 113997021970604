import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormField from '../../../components/FormField';
import SubmitButton from '../../../components/SubmitButton';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import toastOptions from '../../../utils/toastDefault';

const schema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
});

// idea: reset touched if user didn't touch form for over a minute

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      subject: '',
      message: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      setIsSubmitting(true)
      emailjs
        .send(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_CONTACT_ID,
          values,
          process.env.REACT_APP_PUBLIC_KEY
        )
        .then(
          (result) => {
            setIsSubmitting(false)
            console.log(result.text);
            toast.success('Your message has been received.', toastOptions);

            setSubmitted(true);
          },
          (error) => {
            setIsSubmitting(false)
            console.log(error.text);
          }
        );

      // await sleep(500);
      // alert(JSON.stringify(values, null, 2));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="flex flex-col gap-5">
      <ToastContainer />
      <div className="flex flex-col gap-5 md:flex-row lg:flex-col xl:flex-row">
        <FormField formik={formik} name="First Name" required />
        <FormField formik={formik} name="Last Name" required />
      </div>

      <FormField formik={formik} name="Email" required />
      <FormField formik={formik} name="Subject" />
      <FormField formik={formik} name="Message" multiline />

      <SubmitButton submitted={submitted} setSubmitted={setSubmitted} isSubmitting={isSubmitting} />
    </form>
  );
};

export default ContactForm;
