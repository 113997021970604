import { toast } from 'react-toastify';
import toastOptions from './toastDefault';

var isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function () {
    return navigator.userAgent.match(/IEMobile/i);
  },
  any: function () {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  },
};

const messageUs = () => {
  if (isMobile.any()) {
    // mobile device
    window.open('sms:+18452634638', '_blank');
  } else {
    // laptop
    navigator.clipboard.writeText('+18452634638');

    toast.success('Number copied to clipboard.', toastOptions);
  }
};

export default messageUs;
