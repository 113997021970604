import { Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ProviderWrapper from "./context";

// pages
import * as Pages from './pages';

const App = () => {
  return (
    <ProviderWrapper>
      <Header />
      <Routes>
        <Route path="/" element={<Pages.Home />} index />
        <Route path="/services" element={<Pages.Services />} />
      </Routes>
      <Footer />
    </ProviderWrapper>
  );
}

export default App;
