import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";

const useAccessibility = () => {
  const [screenDimensions, setScreenDimensions] = useState();

  const resizeHandler = () => {
    setScreenDimensions({
      height: window.innerHeight,
      width: window.innerWidth
    })
  };

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
  }, []);

  return {
    screenDimensions
  };
};

export default createContainer(useAccessibility);
