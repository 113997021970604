import { TextField } from '@mui/material';
// import { styled } from '@mui/material/styles';
import format from '../utils/formatName';
import React from 'react';

// const StyledField = styled(TextField)({
//   '& label.Mui-focused': {
//     color: 'black',
//   },
//   '& .MuiInput-underline:after': {
//     borderBottomColor: 'black',
//   },
//   '& .MuiOutlinedInput-root': {
//     '&.Mui-focused fieldset': {
//       borderColor: 'black',
//     },
//   },
// });

// field name e.g.: First Name, Email
// is formatted to: firstName,  email
const FormField = ({ formik, name, multiline, required }) => {

  const nameFormatted = format(name);

  const MultilineRows = multiline ? 4 : undefined;

  return (
    <TextField
      fullWidth
      id={nameFormatted}
      name={nameFormatted}
      label={name}
      value={formik.values[nameFormatted]}
      onChange={formik.handleChange}
      error={
        formik.touched[nameFormatted] && Boolean(formik.errors[nameFormatted])
      }
      helperText={formik.touched[nameFormatted] && formik.errors[nameFormatted]}
      variant="outlined"
      multiline={multiline}
      rows={MultilineRows}
      required={required}
      onBlur={() => formik.setTouched({ ...formik.touched, [nameFormatted]: true })}
    />
  );
};

export default FormField;
