import React from 'react';

// context imports
import ServiceSelect from './ServiceSelect';
import Accessibility from './Accessibility';

const ProviderWrapper = ({ children }) => {
  return (
    <ServiceSelect.Provider>
      <Accessibility.Provider>{children}</Accessibility.Provider>
    </ServiceSelect.Provider>
  );
};

export default ProviderWrapper;
