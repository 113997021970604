// field name e.g.: First Name, Email
// is formatted to: firstName,  email
const format = (name) => {
  // first lowercase, delete space
  const regex = /(^.)(\w+) ?(\w+)?/;
  const matches = name.match(regex);
  const nameFormatted =
    matches[1].toLocaleLowerCase() +
    matches[2] +
    (matches[3] ? matches[3] : '');

  return nameFormatted;
}

export default format;