import React from 'react';
// import Select from 'react-select';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import format from '../utils/formatName';

const SelectField = ({ formik, name, options }) => {
  const nameFormatted = format(name);

  return (
    <FormControl>
      <InputLabel id="select-helper-label">{name}</InputLabel>
      <Select
        name={nameFormatted}
        label={name}
        labelId="select-helper-label"
        value={formik.values[nameFormatted]}
        onChange={(e) => {
          formik.setFieldValue(nameFormatted, e.target.value);
        }}
      >
        {options.map((item) => {
          return <MenuItem value={item.value}>{item.label}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};

export default SelectField;
