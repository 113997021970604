import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SubmitButton from '../../components/SubmitButton';
import UserDetails from './UserDetails';
import ChooseService from './ChooseService';
import ServiceSelect from '../../context/ServiceSelect';
import moment from 'moment';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import toastOptions from '../../utils/toastDefault';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Required'),
});

const ServiceForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const { defaultService } = ServiceSelect.useContainer();
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '', // idea: improve with:  https://bit.ly/3OF8zNe
      service: defaultService,
      repairType: '',
      phoneModel: '',
      device: '',
      estimatedPrice: '',
      additionalDetails: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      // send request to backend server
      const current_timestamp = moment().format('dddd MMMM Do, h:mm:ss a'); // Tuesday August 2nd, 11:59:11 am

      const body = {
        form: {
          Date: current_timestamp,
          ...values,
        },
      };

      // send to api
      const response = await fetch(process.env.REACT_APP_API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        const message = `An error has occurred: ${response.status}. Please try again or contact us.`;
        console.log(message);

        toast.error(message, toastOptions);
      } else {
        console.log(response);

        setSubmitted(true);

        // notify user
        toast.success('Form Submission recorded!', toastOptions);
      }

      // send to email (notification)
      emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_SERVICE_ID,
        values,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="flex gap-5 flex-col">
      {/* Same as */}
      <ToastContainer />
      <UserDetails formik={formik} />

      <ChooseService formik={formik} />
      {/* Choose a Service */}

      <SubmitButton submitted={submitted} setSubmitted={setSubmitted} isSubmitting={formik.isSubmitting} />
    </form>
  );
};

export default ServiceForm;
