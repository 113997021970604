import React from 'react';
import { NavLink } from 'react-router-dom';

const HeaderItem = ({ item, clicked }) => {
  const contents = item.title || 'HeaderItem';
  return (
    <div>
      {item.link ? (
        <NavLink
          to={item.link}
          className={({ isActive }) => 'header-item ' + (isActive && 'header-item-active')}
          onClick={clicked}
        >
          {contents}
        </NavLink>
      ) : (
        <div className="hover:cursor-pointer header-item">{contents}</div>
      )}
    </div>
  );
};

export default HeaderItem;
