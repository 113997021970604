import React, { useEffect, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import ClearIcon from '@mui/icons-material/Clear';

import HeaderItem from './HeaderItem';
import ContactDropDown from '../ContactDropDown';
import Accessibility from '../../context/Accessibility';

const Header = () => {
  const [menuShown, setMenuShown] = useState(true);
  const { screenDimensions } = Accessibility.useContainer();
  useEffect(() => {
    if (screenDimensions) {
      if (screenDimensions.width >= 640) {
        setMenuShown(true);
      }
    }
  }, [screenDimensions]);

  useEffect(() => {
    if (window.innerWidth < 640) {
      setMenuShown(false); // default for small screen
    }
  }, []);

  const handleClick = () => {
    if (window.innerWidth < 640) {
      setMenuShown(false);
    }
  };

  return (
    <>
      <div
        className={
          ` fixed w-full top-0 sm:h-12 z-10
          p-3 flex flex-col-reverse sm:flex-row items-center bg-gray-200 text-pr-purple
          pointer-events-none ` + (menuShown ? 'gap-12' : '')
        }
      >
        {/* Header Options List */}
        <div
          // style={{ transition: 'height 0.55s ease-out' }}
          className={
            'flex gap-4 flex-col sm:flex-row items-center pointer-events-auto sm:h-full sm:mb-0 ' +
            (menuShown ? 'h-full ' : 'h-0 overflow-hidden sm:h-full')
          }
        >
          {[
            {
              title: 'Home',
              link: '/',
            },
            {
              title: 'Services',
              link: '/services',
            },
            // { title: 'Contact' }, // todo: Contact dropdown
          ].map((item) => (
            <HeaderItem key={item.title} item={item} clicked={handleClick} />
          ))}
          <ContactDropDown menuShown={menuShown} clicked={handleClick} />
        </div>

        {/* company logo*/}
        <div className="sm:absolute w-full pointer-events-none flex sm:block items-center justify-between">
          <div className="text-3xl sm:mx-auto w-fit pointer-events-auto font-['Allura']">
            Phone Revival
          </div>
          {/* mobile hamburger menu*/}
          {menuShown ? (
            <ClearIcon
              onClick={() => setMenuShown(false)}
              className="mobile-menu-btn"
            />
          ) : (
            <MenuIcon
              onClick={() => setMenuShown(true)}
              className="mobile-menu-btn"
            />
          )}
        </div>
      </div>
      <div className="h-12 w-full" /> {/* since fixed (buffer space) */}
    </>
  );
};

export default Header;
