import React, { useEffect, useState } from 'react';
import FormField from '../../components/FormField';
import SelectField from '../../components/SelectField';
import phoneRepairPrice from '../../constants/price';

const ServiceOptions = [
  { value: 'repair', label: 'Repair' },
  { value: 'cleaning', label: 'Cleaning' },
  { value: 'technicalIssues', label: 'Technical Issues' },
];

// const PhoneModelOptions = [
//   { value: 'iphone12', label: 'Iphone 12' },
//   { value: 'iphone11/pro', label: 'Iphone 11/11 pro' },
//   { value: 'galaxy_s20/s21', label: 'Samsung Galaxy s20/s21' },
//   { value: 'ipadPro', label: 'Ipad pro' },
//   // { value: 'notListed', label: 'My phone model is not listed' },
// ];

const PhoneModelOptions = Object.keys(phoneRepairPrice).map((key) => ({
  value: key,
  label: key,
}));

const DeviceOptions = [
  { value: 'iphone/Android', label: 'Phone - $30' },
  { value: 'laptop', label: 'Laptop - $50' },
  { value: 'iphone/Ipad', label: 'Tablet - $40' },
];

const RepairTypeOptions = [
  { value: 'screen', label: 'Screen' },
  { value: 'battery', label: 'Battery' },
  { value: 'backGlass', label: 'Back Glass' },
];

const ChooseService = ({ formik }) => {
  const [repairTypeOptions, setRepairTypeOptions] = useState(RepairTypeOptions)
  useEffect(() => {
    if (formik.values.phoneModel !== '') {
      const RepairTypeOptionPrices = phoneRepairPrice[formik.values.phoneModel]
      const keys = Object.keys(RepairTypeOptionPrices)
      const res = keys.map(key => ({value: key, label: `${key} - $${RepairTypeOptionPrices[key]}`}))
      setRepairTypeOptions(res);
    }
  }, [formik.values.phoneModel])

  useEffect(() => {
    if (formik.values.repairType !== '' && formik.values.phoneModel !== '') {
      const RepairTypeOptionPrices = phoneRepairPrice[formik.values.phoneModel]

      // set estimatedPrice
      formik.setFieldValue('estimatedPrice', RepairTypeOptionPrices[formik.values.repairType])
    }
  }, [formik.values.phoneModel, formik.values.repairType]);
  return (
    <div>
      <div className="text-center text-5xl m-10 font-['Allura'] text-pr-purple">
        Choose a Service
      </div>

      <div className="flex flex-col gap-6">
        {/* Select service */}
        <SelectField
          key="service"
          formik={formik}
          name="Service"
          options={ServiceOptions}
        />

        {
          // Repair
          formik.values.service === 'repair' && (
            <>
              <SelectField
                key="phoneModel"
                formik={formik}
                name="Phone Model"
                options={PhoneModelOptions}
              />

              {/* Repair type */}
              <SelectField
                key="repairType"
                formik={formik}
                name="Repair Type"
                options={repairTypeOptions}
              />
            </>
          )
        }

        {/* Cleaning */}
        {formik.values.service === 'cleaning' && (
          <SelectField
            key="device"
            formik={formik}
            name="Device"
            options={DeviceOptions}
          />
        )}

        {/* Additional Details */}
        <FormField formik={formik} name="Additional Details" multiline />
      </div>
    </div>
  );
};

export default ChooseService;
