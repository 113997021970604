import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import sleep from '../utils/delay';

const StyledButton = styled(LoadingButton)({
  backgroundColor: 'black',
  ':hover': {
    backgroundColor: '#636363',
  },
});

const SubmitButton = ({ isSubmitting, submitted, setSubmitted }) => {
  const [backgroundColor, setBackgroundColor] = useState('bg-black');
  useEffect(() => {
    (async () => {
      if (submitted) {
        setBackgroundColor('bg-emerald-500');
        // delay
        await sleep(5000);

        setBackgroundColor('bg-black');
        await sleep(500);

        setSubmitted(false);
      }
    })();
  }, [setSubmitted, submitted]);
  return submitted ? (
    <div
      className={`w-full border text-white px-4 py-1.5 text-center rounded font-['Roboto'] font-light ${backgroundColor}`}
      style={{ transition: 'background-color 500ms linear' }}
      // style={{animationName: 'onHoverAnimation', animationDuration: 3000}}
    >
      Submitted!
    </div>
  ) : (
    <StyledButton
      type="submit"
      variant="contained"
      loading={isSubmitting}
      disabled={isSubmitting}
      fullWidth
    >
      Submit
    </StyledButton>
  );
};

export default SubmitButton;
