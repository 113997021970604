import React from 'react'
import { useNavigate } from 'react-router-dom';
import ServiceImageDefault from '../../assets/images/phone-repair-2.jpg';
import ServiceSelect from '../../context/ServiceSelect';

const ServiceBlock = ({ item }) => {
  const { setDefaultService } = ServiceSelect.useContainer();
  const navigate = useNavigate();
  const handleClick = () => {
    // fill in selection (formik)
    setDefaultService(item.id)

    // navigate to service page
    navigate('/services')
  };
  return (
    <div className="
      sm:mx-20
      md:w-4/6 md:m-auto
      lg:mx-20 lg:flex lg:w-auto
      hover:cursor-pointer
      border-2 border-white transition-colors
      hover:border-2 hover:border-pr-blue
      "
      onClick={handleClick}
      >
      <div className="bg-gray-200 flex flex-col gap-4 p-8 sm:p-24 lg:w-1/2 py-auto">
        <div className="m-auto flex flex-col gap-4">
          <div className="text-5xl text-pr-purple font-['Allura']">{item.title || "ServiceBlock"}</div>
          <div className="">{item.body || ''}</div>
        </div>
      </div>
      {/* img */}
      <div className="lg:w-1/2">
        <img className="w-auto h-full object-cover" src={item.img || ServiceImageDefault} alt="service type" />
      </div>
    </div>
  )
}

export default ServiceBlock 