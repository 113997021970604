import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  DropDownContactOptions,
  ArrowContainer,
  Arrow,
  ArrowBackground,
  Options,
  CreateAccountLink,
} from './styled';
import { ToastContainer, toast } from 'react-toastify';
import toastOptions from '../../utils/toastDefault';
import messageUs from '../../utils/messageUs';

const ContactDropDown = ({ clicked, menuShown }) => {
  const [hoverEvent, setHoverEvent] = useState(false);
  const [canExit, setCanExit] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setHoverEvent(false);
  }, [menuShown]);

  const handleClick = () => {
    // toggle hover event
    setHoverEvent((prevValue) => !prevValue);
  };

  const handleMouseLeave = () => {
    if (canExit) {
      setHoverEvent(false);
      setCanExit(false);
    }
  };

  const handleMouseEnter = () => {
    setCanExit(true);
  };

  return (
    <div className="relative" onClick={handleClick}>
      <ToastContainer />
      {/* <div className="flex" onMouseOver={handleClick}> */}
      <div className="flex hover:cursor-pointer justify-center mb-2 sm:mb-0">
        <div className="header-item">Contact</div>
        <ArrowDropDownIcon className="pt-1" />
      </div>
      {hoverEvent && (
        <DropDownContactOptions
          className="sm:absolute flex flex-col"
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleMouseEnter}
        >
          <ArrowContainer>
            <Arrow className="absolute top-[34px] sm:top-[2px]" />
            <ArrowBackground />
          </ArrowContainer>
          <Options className="bg-gray-200">
            {/* <a
              className="
                contact-dropdown-link text-[#0071dc] border-[#0071dc] 
                hover:bg-[#0071dc] hover:text-white hover:border-[#0071dc]
              "
              href="sms:+18452634638?body=hi" // todo: fix messaging for all platforms
              onClick={() => {
                // copy number to clipboard

                toast.success('Number copied to clipboard.', toastOptions);

                clicked();
              }}
            >
              <div className="text-sm">Message US</div>
            </a> */}

            <div
              className="
             contact-dropdown-link text-[#0071dc] border-[#0071dc] 
             hover:bg-[#0071dc] hover:text-white hover:border-[#0071dc]
           "
              onClick={() => {
                // copy number to clipboard

                messageUs();

                clicked();
              }}
            >
              <div className="text-sm">Message US</div>
            </div>
            <CreateAccountLink
              smooth
              to="/#contact-us"
              onClick={() => {
                clicked();
              }}
            >
              <div className="text-sm">Email US</div>
            </CreateAccountLink>
          </Options>
        </DropDownContactOptions>
      )}
    </div>
  );
};

export default ContactDropDown;
