import React from 'react';
import ContactUsImg from '../../../assets/images/contact-us.jpg';
import ContactForm from './ContactForm';

const ContactUS = () => {
  return (
    <div
      className="
      flex
      border-2 border-white
      sm:mx-20
      md:w-4/6 md:m-auto
      lg:w-auto lg:mx-20
      "
      id="contact-us"
    >
      <div className="bg-gray-200 flex flex-col gap-4 p-18 w-full lg:w-1/2 py-auto focus-within:border-pr-blue focus-within:border-2">
        <div className="flex flex-col gap-10 p-8 sm:p-24">
          <div className="text-5xl text-center text-pr-purple font-['Allura']">Contact Us</div>
          <ContactForm />
          {/* <div className="">Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis architecto vitae quibusdam, sed laudantium modi pariatur dolores perspiciatis commodi qui ab ratione enim unde! Est, aperiam. Ullam, eveniet veniam?</div> */}
        </div>
      </div>
      {/* img */}
      <div className="hidden lg:block lg:w-1/2">
        <img
          className="w-auto h-full object-cover"
          src={ContactUsImg}
          alt="service type"
        />
      </div>
    </div>
  );
};

export default ContactUS;
