import styled from 'styled-components';
// import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';

export const DropDownContactOptions = styled.div`

    right: -48px;
    top: 30px;
`;

export const ArrowContainer = styled.div`
    width: 170px;
    height: 10px;
    display: flex;
    justify-content: center;
`;

export const Arrow = styled.div`
    width: 10px;
    height: 0;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid #e5e7eb;
    z-index: 12;
`;

export const ArrowBackground = styled(Arrow)`
    border-right: 14px solid transparent;
    border-left: 14px solid transparent;
    border-bottom: 14px solid #777777;
    top: 0px;
    z-index: 10;
`;

export const Options = styled.div`
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 170px;
    height: 120px;
    padding: 14px;
    border: 1px solid #6d6d6d;
    border-radius: 6px;
    box-shadow: 0px 0px 15px #616161;
    z-index: 11;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: black;
    text-align: center;
    
    border: 1px solid black;
    border-radius: 4px;
    padding: 2px;
    cursor: pointer;
    
    &:hover{
        color: black;
    }
`;

export const CreateAccountLink = styled(StyledLink)`
    &:hover{
        color: white;
        background: black;
    }
`;
