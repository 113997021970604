import React from 'react';
import FormField from '../../components/FormField';

const UserDetails = ({formik}) => {
  return (
    <div className="flex flex-col gap-5">
      <div className="text-center text-5xl m-10 font-['Allura'] text-pr-purple">User Details</div>
      <div className="flex flex-col md:flex-row gap-5">
        <FormField formik={formik} name="First Name" required />
        <FormField formik={formik} name="Last Name" required />
      </div>
      <FormField formik={formik} name="Phone Number" required />
    </div>
  );
};

export default UserDetails;
